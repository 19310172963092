import React from "react";
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import './style.css';


const BannerRight = () => {
    return (
        <>
            <section className='br-container'>
                <h1>Central de atendimento</h1>

                <div className='br-image-wrapper'>
                    <StaticImage
                        title="VEGA I.T - Construindo o futuro da hospitalidade"
                        className='br-image'
                        src='../../images/home/veganauta.png'
                        placeholder="blurred"
                        loading="lazy"
                        alt="Veganauta | Vega I.T"
                    />
                </div>
                <Link href="https://vegait.com/" target="_blank" alt="VEGA I.T">
                    <div className="br-icon-wrapper">
                        <StaticImage
                            title="VEGA I.T - Construindo o futuro da hospitalidade"
                            className='br-icon'
                            src='../../images/icones/logo.svg'
                            loading="lazy"
                            alt="Veganauta | Vega I.T"
                        />
                    </div>
                </Link>
            </section>
        </>
    )
};

export default BannerRight;