import React from "react";
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby'
import './style.css';

const MenuLeft = () => {
    return (
        <>
            <main className='ml-container'>
                <h2>Suporte técnico</h2>
                <ConteudoAbsoluto />
                <BotaoMovieDesk nome={'Atendimento online'} />
                <Botao nome={'Acesse seus chamados'} rota={'https://suporte.vegait.com.br/'} target={'blank'} icone={<StaticImage className='ml-icone' src="../../images/icones/01.svg" width={50} height={50} loading="lazy" alt="Vega I.T" />} />
                <Botao nome={'Base de conhecimento'} rota={'http://vega-it.movidesk.com/kb'} target={'blank'} icone={<StaticImage className='ml-icone' src="../../images/icones/02.svg" width={50} height={50} loading="lazy" alt="Vega I.T" />} />
                <Botao nome={'suporte@vegait.com'} rota={'mailto:suporte@vegait.com'} icone={<StaticImage className='ml-icone' src="../../images/icones/03.svg" width={50} height={50} loading="lazy" alt="Vega I.T" />} />
                <h3>Outros contatos</h3>
                <Botao nome={'Financeiro'} rota={'/financeiro'} target={'blank'} icone={<StaticImage className='ml-icone' src="../../images/icones/04.svg" width={50} height={50} loading="lazy" alt="Vega I.T" />} />
                <Botao nome={'Comercial'} rota={'/comercial'} target={'blank'} icone={<StaticImage className='ml-icone' src="../../images/icones/05.svg" width={50} height={50} loading="lazy" alt="Vega I.T" />} />
                <Botao nome={'(41) 3204-0300'} rota={'tel:4132040300'} icone={<StaticImage className='ml-icone' src="../../images/icones/06.svg" width={50} height={50} loading="lazy" alt="Vega I.T" />} />
                <div className="ml-logo-bottom">
                    <a href="https://vegait.com/" target="blank" alt="Vega i.t">
                        <div className="ml-logo-bottom-image">
                            <StaticImage style={{width: '100%'}} src="../../images/icones/logo.svg" loading="lazy" alt="VEGA I.T" />
                        </div>
                    </a>
                </div>
            </main>
        </>
    )
};

const BotaoMovieDesk = ({ nome }) => {
    return (
        <>
            <a href="http://chat.movidesk.com/ChatWidget/Login/A089E5CBA9CA4DFBAFC27FA1DA2B6CC8?StartChat=true" target="blank" className='ml-menu-item-dark' alt="VEGA I.T" >
                {nome}
            </a>
        </>
    )
}

const ConteudoAbsoluto = () => {
    return (
        <>
            <div className="ml-ca-container">
                <i className="ml-ca-icon"><MdOutlineArrowBackIosNew /></i>
                <h4>Pelo chat seu atendimento é mais <br /> rápido e prático. Acesse agora mesmo!</h4>
            </div>
        </>
    )
}

function Botao({ nome, rota, icone, target }) {
    return (
        <>
            <Link className='ml-menu-item' href={rota} target={target} alt="VEGA I.T" >
                {icone}
                {nome}
            </Link>
        </>
    )
}

export default MenuLeft;