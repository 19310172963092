import React from "react";
import './style.css';

import BannerRight from "../BannerRight";
import MenuLeft from "../MenuLeft";

const HomeComponent = () => {

    return (
        <>
            <section className='home-alinhamento'>
                <MenuLeft />
                <BannerRight />
            </section>
        </>
    )
};

export default HomeComponent;