import React from "react";
import './style.css';

const Background = (props) => {

    return (
        <>
            <section className='bg-position'>
                <div className="bg-img"></div>
                <div className='bg-background'>
                    {props.children}
                </div>
            </section>
        </>
    )
};

export default Background;