import React from "react";
import '../style.scss';

import Head from "../components/Head/head";
import Background from "../components/Background";
import HomeComponent from "../components/home";

const IndexPage = () => {
  return (
    <>
      <Background>
        <Head />
        <HomeComponent />
      </Background>
    </>
  );
};

export default IndexPage;